import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { baseUrl, setToken } from '../../actions/configs';

export default class Login extends React.Component {

    state = {
        loading: false,
    }

    login = (e) => {
        e.preventDefault();
        if (this.state.loading) return;
        this.setState({ loading: true });

        const email = (document.getElementById("email") as HTMLInputElement).value.toLowerCase();
        const password = (document.getElementById("password") as HTMLInputElement).value;
        const url = baseUrl + "/users/login";
        const data = {
            mobile_or_email: email,
            password: password
        }


        axios.post(url, data, { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', } }).then(r => {
            const token = r.data.token;
            if (token) {
                setToken(token);
            }
        }).catch(e => {
            this.setState({ loading: false });
        });
        // fetch(url, {
        //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //     mode: 'cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     credentials: 'same-origin', // include, *same-origin, omit
        //     headers: {
        //         'Content-Type': 'application/json'
        //         // 'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        //     // redirect: 'follow', // manual, *follow, error
        //     // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //     body: JSON.stringify(data) // body data type must match "Content-Type" header
        // }).then(r => {
        //     debugger
        // }).catch(e=>{
        //     debugger
        // });
    }

    render() {
        return (
            <div style={{ width: '80%', maxWidth: 550, margin: '15px auto', border: '1px solid #aaa', borderRadius: 6, padding: 10, textAlign: 'center', background: 'white' }}>
                <h2>התחברות</h2>
                <form onSubmit={this.login}>
                    <div>
                        <TextField fullWidth={true} dir="ltr" id="email" label="Email" name="email" className={"input"} />
                        <TextField fullWidth={true} dir="auto" type="password" id="password" label="Password" name="password" className={"input"} />
                    </div>
                    <Button type="submit" disabled={this.state.loading} onClick={this.login} variant="contained" color="primary" fullWidth={true} style={{ width: '80%', margin: 10, marginTop: 25 }}>
                        Login
                    </Button>
                </form>
            </div>
        )
    }

} 