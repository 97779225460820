import axios from "axios";
import { baseUrl, getHeaders } from "./configs";

const buildingsUrl = baseUrl + "/buildings";

export function getBuildings(callback) {
  axios
    .get(buildingsUrl, { headers: getHeaders() })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      console.log(JSON.stringify(e));
    });
}

export function getApartments(buildingId, callback) {
  axios
    .get(buildingsUrl + "/" + buildingId + "/apartments", {
      headers: getHeaders(),
    })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      console.log(JSON.stringify(e));
    });
}

export function addBuilding(data, callback) {
  axios
    .post(buildingsUrl, data, { headers: getHeaders() })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      callback(false);
    });
}

export function addApartment(buildingId, data, callback) {
  axios
    .post(buildingsUrl + "/" + buildingId + "/apartments", data, {
      headers: getHeaders(),
    })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      callback(false);
    });
}

export function editApartment(buildingId, apartmentId, data, callback) {
  axios
    .put(buildingsUrl + "/" + buildingId + "/apartments/" + apartmentId, data, {
      headers: getHeaders(),
    })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      callback(false);
    });
}

export function editBuilding(buildingId, data, callback) {
  axios
    .put(buildingsUrl + "/" + buildingId, data, { headers: getHeaders() })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      callback(false);
    });
}

export function deleteBuilding(buildingId, callback) {
  axios
    .delete(buildingsUrl + "/" + buildingId, { headers: getHeaders() })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      callback(false);
    });
}

export function deleteApartment(buildingId, apartmentId, callback) {
  axios
    .delete(buildingsUrl + "/" + buildingId + "/apartments/" + apartmentId, {
      headers: getHeaders(),
    })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      callback(false);
    });
}

export function getSummaryData(callback) {
  axios
    .get(buildingsUrl + "/summary_data", { headers: getHeaders() })
    .then((r) => {
      callback(r.data);
    })
    .catch((e) => {
      callback(false);
    });
}
