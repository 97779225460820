import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Apartments from "./Apartments";
import {
  getBuildings,
  addBuilding,
  editBuilding,
  deleteBuilding,
  getSummaryData,
} from "../actions/actions";
import { Building } from "../models/models";
import {
  Button,
  Card,
  IconButton,
  Modal,
  TextField,
  Toolbar,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import useLongPress from "./general/useLongPress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EnterIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import moment from "moment";
var numeral = require("numeral");

const theme = createMuiTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});

interface Props {
  history: any;
}

const SummaryCard = ({ text, data, onClick }) => {
  return (
    <Card
      className="summary-card"
      style={{
        marginRight: 20,
        marginLeft: 20,
        flexGrow: 1,
        textAlign: "center",
        borderRadius: 10,
        lineHeight: 1,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box padding={3}>
        <Box>
          <span>{text} ({data.count})</span>
        </Box>
        <Box>
          <h3>{numeral(data.total).format("0,0")} ₪ </h3>
        </Box>
      </Box>
    </Card>
  );
};

const SummaryData = () => {
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState("");
  useEffect(() => {
    getSummaryData((data) => {
      if (data) {
        var newData = {};
        newData["running"] = data.filter((x) => !x.expired);
        newData["expired"] = data.filter((x) => x.expired);
        newData["summaryRunning"] = {
          total: newData["running"].reduce((a, b) => {
            return a + (parseInt(b.total) || 0);
          }, 0),
          count: newData["running"].reduce((a, b) => {
            return a + (parseInt(b.count) || 0);
          }, 0),
        };
        newData["summaryExpired"] = {
          total: newData["expired"].reduce((a, b) => {
            return a + (parseInt(b.total) || 0);
          }, 0),
          count: newData["expired"].reduce((a, b) => {
            return a + (parseInt(b.count) || 0);
          }, 0),
        };
        setData(newData);
      }
    });
  }, []);
  if (data) {
    return (
      <>
        <Modal
          open={!!selectedData}
          onClose={() => setSelectedData("")}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal"
        >
          <ThemeProvider theme={theme}>
            <div className="inner-modal">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align={"right"} padding={"default"}>
                        ישות
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        style={{ width: 100 }}
                      >
                        סה״כ
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[selectedData]?.map((row: any, index: number) => (
                      <TableRow className={"building-row "} key={index}>
                        <TableCell align="right" style={{}}>
                          <span style={{ fontWeight: "bold", fontSize: 18 }}>
                            {row.organization}
                          </span>
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          style={{ width: 120 }}
                        >
                          {numeral(row.total).format("0,0")} ₪ 
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                onClick={() => setSelectedData("")}
                style={{ margin: "10px auto" }}
                variant="contained"
              >
                סגור
              </Button>
            </div>
          </ThemeProvider>
        </Modal>
        <Box
          display="flex"
          width={600}
          margin="auto"
          justifyContent="space-between"
        >
          <SummaryCard
            text={"חוזים פעילים"}
            data={data.summaryRunning}
            onClick={() => {
              setSelectedData("running");
            }}
          />
          <SummaryCard
            text={"חוזים לא פעילים"}
            data={data.summaryExpired}
            onClick={() => {
              setSelectedData("expired");
            }}
          />
        </Box>
      </>
    );
  } else {
    return null;
  }
};

class Buildings extends React.Component<Props> {
  state = {
    buildings: [],
    buildingModalOpen: false,
    saving: false,
    editData: null,
    loading: true,
    search: "",
  };

  componentDidMount() {
    this.setState({ loading: true });
    getBuildings((buildings) => {
      if (buildings) {
        this.setState({ buildings, loading: false });
      }
    });
  }

  getOrganizations(buildings: Building[]) {
    if (!buildings?.length) return [];
    let organizations = [];
    buildings.forEach((b) => {
      if (b.organization && !organizations.includes(b.organization)) {
        organizations.push(b.organization);
      }
    });
    return organizations;
  }

  handleChange = (event: any, newValue: any) => {
    this.setState({ selected: newValue });
  };

  handleClose = () => {
    this.setState({ buildingModalOpen: false, editData: null });
  };

  deleteBuilding = () => {
    if (this.state.saving) return;
    deleteBuilding(this.state.editData.id, (buildings) => {
      if (buildings) {
        this.setState({
          saving: false,
          buildings,
          buildingModalOpen: false,
          editData: null,
          selected: 0,
        });
      } else {
        this.setState({ saving: false });
      }
    });
  };

  addBuilding = (e) => {
    if (e) e.preventDefault();
    let name = (
      document.getElementById("build_name") as HTMLInputElement
    ).value.trim();
    let organization = (
      document.getElementById("organization") as HTMLInputElement
    ).value.trim();
    if (this.state.saving || !name) return;
    this.setState({ saving: true });
    const data = { name, organization };
    if (this.state.editData) {
      editBuilding(this.state.editData.id, data, (buildings) => {
        if (buildings) {
          this.setState({
            saving: false,
            buildings,
            buildingModalOpen: false,
            editData: null,
          });
        } else {
          this.setState({ saving: false });
        }
      });
    } else {
      addBuilding(data, (buildings) => {
        if (buildings) {
          this.setState({
            saving: false,
            buildings,
            buildingModalOpen: false,
            selected: 0,
            editData: null,
          });
        } else {
          this.setState({ saving: false });
        }
      });
    }
  };

  showAddModal = () => {
    this.setState({ buildingModalOpen: true, editData: null });
  };

  logout = () => {
    localStorage.removeItem("a_t");
    window.location.reload();
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div style={{ margin: "auto", width: 200, textAlign: "center" }}>
            <span>טוען נתונים</span>
            <div className="loader"></div>
          </div>
        ) : (
          <Router>
            <Switch>
              <Route path="/apartments/:id">
                <Apartments />
              </Route>
              <Route path="/">{this.renderBuildings()}</Route>
            </Switch>
          </Router>
        )}
        {this.renderAddBuildingModal()}
      </div>
    );
  }

  renderAddBuildingModal = () => {
    if (!this.state.buildingModalOpen) return null;
    const options = this.getOrganizations(this.state.buildings);
    return (
      <Modal
        open={this.state.buildingModalOpen}
        onClose={this.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal"
      >
        <ThemeProvider theme={theme}>
          <div className="inner-modal">
            <h2>{this.state.editData?.id ? "ערוך בניין" : "בניין חדש"}</h2>
            <form onSubmit={this.addBuilding}>
              <div>
                <TextField
                  dir={"rtl"}
                  defaultValue={
                    this.state.editData?.name ? this.state.editData?.name : ""
                  }
                  id="build_name"
                  label="שם בניין"
                  name="name"
                  className={"input"}
                />
                <Autocomplete
                  componentName="organization-auto"
                  id="organization"
                  defaultValue={
                    this.state.editData?.organization
                      ? this.state.editData?.organization
                      : ""
                  }
                  freeSolo
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      dir={"rtl"}
                      defaultValue={
                        this.state.editData?.organization
                          ? this.state.editData?.organization
                          : ""
                      }
                      label="שם ישות"
                      name="organization"
                      className={"input"}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <Button
                  disabled={this.state.saving}
                  onClick={this.addBuilding}
                  variant="contained"
                  color="primary"
                >
                  {this.state.editData?.id ? "שמור" : "הוסף"}
                </Button>

                {this.state.editData?.id ? (
                  <Button
                    disabled={this.state.saving}
                    onClick={this.deleteBuilding}
                    variant="contained"
                  >
                    מחק
                  </Button>
                ) : null}
              </div>
            </form>
          </div>
        </ThemeProvider>
      </Modal>
    );
  };

  onLongPress = (building) => {
    this.setState({ buildingModalOpen: true, editData: building });
  };

  onClick = (building: Building) => {
    this.props.history.push("/apartments/" + building.id);
  };

  renderBuildings = () => {
    return (
      <div style={{ width: "100%", maxWidth: 1300, margin: "auto" }}>
        {<SummaryData />}
        <TextField
          dir={"rtl"}
          value={this.state.search}
          onChange={(e) =>
            this.setState({ search: e.target.value.toLowerCase() })
          }
          style={{ maxWidth: 300 }}
          id="search"
          label="חיפוש"
          name="search"
          className={"input"}
        />
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align={"right"} padding={"default"}>
                  שם
                </TableCell>
                <TableCell size="small" align="center" style={{ width: 120 }}>
                  ישות
                </TableCell>
                <TableCell size="small" align="center" style={{ width: 100 }}>
                  מס' דירות
                </TableCell>
                <TableCell size="small" style={{ width: 100 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.showAddModal}
                  >
                    הוסף
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.buildings
                .filter((x) => x.name.includes(this.state.search.trim()))
                .map((row: Building, index: number) => (
                  <Row
                    onClick={() => this.onClick(row)}
                    openEdit={() =>
                      this.setState({ buildingModalOpen: true, editData: row })
                    }
                    key={row.id}
                    row={row}
                    index={index}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
}

const BuildingsWithRouter = Buildings;
export default BuildingsWithRouter;

interface RowProps {
  row: Building;
  index: number;
  openEdit(): void;
  onClick(): void;
}

const defaultOptions = {
  shouldPreventDefault: true,
  delay: 500,
};

function Row(props: RowProps) {
  const { row, openEdit } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory({});

  const onClick = () => history.push("/apartments/" + row.id);
  const timePassed =
    props.row.min_time && moment().add(1, "M").isAfter(props.row.min_time);

  return (
    <React.Fragment>
      <TableRow
        className={"building-row " + (timePassed ? "row-time-passed" : "")}
      >
        {/* <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell> */}
        <TableCell onClick={onClick} align="right" style={{}}>
          <span style={{ fontWeight: "bold", fontSize: 18 }}>{row.name}</span>
        </TableCell>
        <TableCell size="small" align="center" style={{ width: 120 }}>
          {row.organization}
        </TableCell>
        <TableCell
          size="small"
          align="center"
          style={{ width: 100, fontWeight: "bolder" }}
        >
          {row.apartments}
        </TableCell>
        <TableCell align="center">
          <EditIcon
            onClick={openEdit}
            style={{ cursor: "pointer", marginLeft: 40 }}
            color="primary"
          />
          <EnterIcon
            onClick={onClick}
            style={{ cursor: "pointer" }}
            color="primary"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
