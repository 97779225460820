export const baseUrl = "/api";
// export const baseUrl = "http://localhost:3030/api";

export function getToken() {
    return localStorage.getItem("a_t");
}

export function setToken(token) {
    localStorage.setItem("a_t", token);
    window.location.reload();
}

export function getHeaders() {
    return { 'Authorization': getToken() }
}
