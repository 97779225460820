import logo from './logo.svg';
import './App.css';
import Buildings from './componentes/Buildings';
import Login from './componentes/login/LoginPage';
import { getToken } from './actions/configs';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';

import { Button, IconButton, Modal, TextField, Toolbar } from '@material-ui/core';

let loggedin = getToken() ? true : false;

const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

function logout() {
  localStorage.removeItem("a_t");
  window.location.reload();
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {loggedin ?
          <>
            <AppBar position="fixed" color="default">
              <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: 90 }} />
                <h2>Dalal's</h2>
                <Button variant="contained" color="primary" onClick={logout}>התנתק</Button>
              </Toolbar>
            </AppBar>
            <div style={{ marginTop: 90, marginBottom: 50, marginRight: 10, marginLeft: 10 }}>
              <Buildings />
            </div>
          </>
          :
          <Login />
        }
      </ThemeProvider>
    </div>
  );
}

export default App;
