import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { getApartments, addApartment, editApartment, deleteApartment } from '../actions/actions';
import { Button, Checkbox, createMuiTheme, lighten, Modal, TableSortLabel, TextField, ThemeProvider, Toolbar, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { withRouter } from "react-router";
import Popover from '@material-ui/core/Popover';


declare const window: any;

var numeral = require('numeral');

const dateRegex = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/);

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});


interface Apartment {
    id: number,
    building_id: number,
    name?: string,
    remark?: string,
    created_at: string,
    last_update_time: string,
    res_name?: string,
    res_price?: number,
    res_end_time?: string,
    aprt_number: number,
    contracts: Array<string>
}

const getDate = (date) => {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY");
}

interface RowProps {
    row: Apartment,
    isItemSelected: boolean,
    index: number,
    openEdit(): void
}

function Row(props: RowProps) {
    const { row, isItemSelected, openEdit } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const labelId = `enhanced-table-checkbox-${props.index}`;

    const timePassed = props.row.res_end_time && moment().add(1,'M').isAfter(props.row.res_end_time);
    const contracts = row?.contracts?.length;
    return (
        <React.Fragment>
            <TableRow className={timePassed ? "row-time-passed" : ""}>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell> */}
                <TableCell align="right" style={{ paddingRight: 40, width: 75 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>{row.aprt_number}</span>
                </TableCell>
                <TableCell style={{ paddingRight: 40 }} align="right">{row.res_name}</TableCell>
                <TableCell style={{ paddingRight: 40, width: 80, fontWeight: 'bold', fontSize: 15 }} align="right">{row.res_price ? numeral(row.res_price).format('0,0') : row.res_price}</TableCell>
                <TableCell style={{ paddingRight: 40 }} align="right" >{getDate(row.res_end_time)}</TableCell>
                <TableCell style={{ paddingRight: 40 }} align="right">{row.remark}</TableCell>
                <TableCell style={{ paddingRight: 40, width: 50 }} align="center">
                    <span style={{ fontWeight: contracts ? 'bold' : "normal", fontSize: contracts ? 16 : 14, color: contracts ? '#3f51b5' : 'black', cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                        {contracts || 0}
                    </span>
                </TableCell>
                <TableCell style={{ paddingRight: 40 }} align="center"><EditIcon color="primary" style={{ cursor: 'pointer' }} onClick={openEdit} /></TableCell>
            </TableRow>
            {open ? <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} align="right">
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Typography variant="h6" gutterBottom component="div">
                            קבצים
                            </Typography>
                        {row.contracts?.map((c, idx) => {
                            return <div key={idx} style={{ margin: 10 }}><a href={c} style={{ fontSize: 16 }} target="_blank">• קובץ {idx + 1}</a></div>
                        })}
                    </Collapse>
                </TableCell>
            </TableRow>
                : null}
        </React.Fragment >
    );
}

interface Props {
    match: any,
    location: any,
    history: any
}

class Apartments extends React.Component<Props> {

    state = {
        apartments: [],
        loading: true,
        selected: [],
        order: null,
        orderBy: "",
        showAddApartment: false,
        saving: false,
        editData: null,
        buildingName: "",
        contracts: [],
    }

    componentDidMount() {
        this.getApartments();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getApartments();
        }
    }

    getApartments() {
        this.setState({ apartments: [], loading: true });
        getApartments(this.props.match.params.id, (res) => {
            if (res) {
                this.setState({ apartments: res.apartments, buildingName: res.building_name, loading: false });
            } else {
                this.setState({ loading: false, apartments: [] });
            }
        })
    }

    handleSelectAllClick = () => {

    }


    handleRequestSort = () => {

    }

    deleteApartment = () => {
        if (this.state.saving) return;

        this.setState({ saving: true });
        deleteApartment(this.props.match.params.id, this.state.editData.id, (res) => {
            if (res.apartments) {
                this.setState({ apartments: res.apartments, saving: false, showAddApartment: false, editData: null, contracts: [] });
            } else {
                this.setState({ saving: false });
            }
        });
    }

    addApartment = (e) => {
        if (e) {
            e.preventDefault();
        }
        let aprt_number = (document.getElementById("aprt_number") as HTMLInputElement).value.trim();
        let remark = (document.getElementById("remark") as HTMLInputElement).value.trim();
        let res_name = (document.getElementById("res_name") as HTMLInputElement).value.trim();
        let res_price = (document.getElementById("res_price") as HTMLInputElement).value.trim();
        let res_end_time = (document.getElementById("res_end_time") as HTMLInputElement).value.trim();
        if (this.state.saving || !aprt_number) return;
        if (res_end_time && !dateRegex.test(res_end_time)) {
            window.alert("תאריך שגוי");
            return;
        };
        const data = {
            name: "",
            remark,
            res_name,
            res_price,
            res_end_time: res_end_time ? moment(res_end_time, "DD-MM-YYYY").set("hour", 10).toISOString() : null,
            aprt_number,
            contracts: this.state.contracts
        }

        this.setState({ saving: true });

        if (this.state.editData?.id) {
            editApartment(this.props.match.params.id, this.state.editData.id, data, (res) => {
                if (res?.apartments) {
                    this.setState({ apartments: res.apartments, saving: false, showAddApartment: false, editData: null, contracts: [] });
                } else {
                    this.setState({ saving: false });
                }
            });
        }
        else {
            addApartment(this.props.match.params.id, data, (res) => {
                if (res?.apartments) {
                    this.setState({ apartments: res.apartments, saving: false, showAddApartment: false, editData: null, contracts: [] });
                } else {
                    this.setState({ saving: false });
                }
            })
        }
    }

    handleClose = () => {
        this.setState({ showAddApartment: false, editData: null, contracts: [] });
    }

    openEdit = (row: Apartment) => {
        this.setState({ showAddApartment: true, editData: row, contracts: row.contracts ? row.contracts : [] });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    openPicker = () => {
        // var myCropWidget = window.cloudinary.createUploadWidget({
        //     cloudName: 'dlzwop7k7', uploadPreset: 'mdry44rt', folder: 'contracts'
        // },
        //     (error, result) => { console.log(error, result) });

        window.cloudinary.openUploadWidget({
            cloudName: "dlzwop7k7", uploadPreset: "mdry44rt", folder: 'contracts',
            sources: ['local'],
        }, (error, result) => {
            if (result?.event === "success") {
                let contracts = this.state.contracts || [];
                contracts.push(result.info.url);
                this.setState({ contracts });
            }
        });

    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" style={{ position: 'fixed', marginTop: -70, marginRight: 15, zIndex: 1110 }} onClick={this.goBack}>חזור</Button>
                {this.state.loading ? <div style={{ margin: 'auto', width: 200, textAlign: 'center' }}>
                    <span>טוען נתונים</span>
                    <div className="loader"></div>
                </div>
                    :
                    <div style={{ width: '100%', maxWidth: 1300, margin: '0px auto' }}>
                        <h2>{this.state.buildingName}</h2>
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="sticky table">
                                <EnhancedTableHead
                                    numSelected={this.state.selected.length}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={this.state.apartments.length}
                                    addApartment={() => this.setState({ showAddApartment: true })}
                                />
                                <TableBody>
                                    {this.state.apartments.map((row: Apartment, index: number) => (
                                        <Row openEdit={() => this.openEdit(row)} key={row.aprt_number} row={row} isItemSelected={this.state.selected.includes(row.id)} index={index} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
                <Modal
                    open={this.state.showAddApartment}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="modal"

                >
                    {this.renderModal()}
                </Modal>
            </div>
        )
    }

    renderModal = () => {
        const { showAddApartment, editData } = this.state;
        if (!showAddApartment) return null;
        let apartmentNum = editData?.aprt_number ? editData.aprt_number : (this.state.apartments?.length ? parseInt(this.state.apartments[this.state.apartments.length - 1]?.aprt_number) + 1 : "");
        return (
            <ThemeProvider theme={theme}>
                <div className="apartment-modal">
                    <h2>{this.state.editData?.id ? "ערוך דירה" : "הוספת דירה חדשה"}</h2>
                    <form onSubmit={this.addApartment}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <TextField defaultValue={apartmentNum} dir={'rtl'} id="aprt_number" label="מספר דירה" name="aprt_number" className={"input"} />
                            <TextField dir={'rtl'} style={{ minWidth: 350}} defaultValue={editData?.res_name} id="res_name" label="שם דייר" name="res_name" className={"input"} />
                            <TextField dir={'rtl'} defaultValue={editData?.res_price} id="res_price" label="מחיר" name="res_price" className={"input"} />
                            <TextField dir={'rtl'} style={{ minWidth: 200}} defaultValue={getDate(editData?.res_end_time)} id="res_end_time" label="סוף שכירות 01/01/1990" name="res_end_time" className={"input"} />
                            <TextField dir={'rtl'} style={{ minWidth: 350}} defaultValue={editData?.remark} id="remark" label="הערות" name="remark" className={"input"} />
                        </div>

                        {this.state.contracts?.length ?
                            this.state.contracts.map((c, idx) => <div key={idx} style={{ display: 'flex', justifyContent: 'space-between', margin: 5, border: '1px solid #bbb', borderRadius: 5, padding: 12 }}>
                                <a target="_blank" href={c}>הצג קובץ</a>
                                <a style={{ color: 'red', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { this.state.contracts.splice(idx, 1); this.setState({}); }}>מחק קובץ</a>
                            </div>
                            )
                            :
                            null
                        }
                        <Button onClick={this.openPicker} style={{ marginTop: 10 }} variant="contained" color="primary">הוסף קובץ</Button>

                        {
                            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 30, borderTop: '1px solid #ccc', paddingTop: 10 }}>
                                <Button disabled={this.state.saving} onClick={this.addApartment} style={{ minWidth: 120 }} variant="contained" color="primary" >
                                    {this.state.editData?.id ? "שמור" : "הוסף דירה"}
                                </Button>

                                {this.state.editData?.id ? <Button disabled={this.state.saving} style={{ minWidth: 120 }} onClick={this.deleteApartment} variant="contained" >
                                    מחק
                                    </Button> : null}
                            </div>
                        }
                    </form>
                </div>
            </ThemeProvider >
        )
    }
}

const ApartmentsRouter = withRouter(Apartments);
export default ApartmentsRouter;

const theme = createMuiTheme({
    direction: 'rtl', // Both here and <body dir="rtl">
});


const headCells = [
    { id: 'aprt_number', numeric: true, label: 'מספר דירה' },
    { id: 'res_name', numeric: false, label: 'שוכר' },
    { id: 'res_price', numeric: true, label: 'מחיר' },
    { id: 'res_end_time', numeric: false, label: 'סוף שכירות' },
    { id: 'remarks', numeric: false, label: 'הערות' },
    { id: 'contracts', numeric: true, label: 'קבצים' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, addApartment } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'right'}
                        padding={"default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell size="small" style={{ width: 60 }}>
                    <Button variant="contained" color="primary" onClick={addApartment}>הוסף</Button>
                </TableCell>

            </TableRow>
        </TableHead>
    );
}


EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    addApartment: PropTypes.func.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        Nutrition
                    </Typography>
                )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                    <Tooltip title="Filter list">
                        <IconButton aria-label="filter list">
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};